body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "besom";
  src: url(/static/media/BasementGrotesque-Black_v1.202.069afac8.otf) format("opentype"), url(/static/media/BasementGrotesque-Black_v1.202.5bf8c222.woff2) format("woff2"), url(/static/media/BasementGrotesque-Black_v1.202.44b58d9f.woff) format("woff");
  font-weight: black;
  font-style: normal;
}
*:focus {
  outline: none;
}

body {
  font-family: "Montserrat", sans-serif;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-family: "besom";
}

header.clkHead {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 0.3s all ease;
}
header.clkHead .clkNav {
  display: flex;
  align-items: center;
  height: 100px;
  transition: 0.3s all ease;
}
header.clkHead .clkNav .navLogo {
  display: inline-block;
}
header.clkHead .clkNav .navLogo img {
  height: 100px;
  width: auto;
  transition: 0.3s all ease;
}
header.clkHead .clkNav .navToggler {
  display: none;
}
header.clkHead .clkNav .navComp {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
header.clkHead .clkNav .navComp > ul {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
header.clkHead .clkNav .navComp > ul li a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 500;
}
header.clkHead .clkNav .navComp > ul li a.sp-item {
  color: #FAD32E;
  font-weight: 700;
}
header.clkHead .clkNav .navComp > ul li a.navLink.active {
  color: #FAD32E;
}
header.clkHead .clkNav .navComp > ul li .langSelector {
  background-color: #dcdcdc;
  border: 1px solid #dcdcdc;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
}
header.clkHead .clkNav .navComp > ul li .langSelector ul.langList {
  position: absolute;
  display: none;
  right: 0px;
  top: calc(100% + 5px);
  background-color: rgba(220, 220, 220, 0.5);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 10px;
  padding: 15px;
}
header.clkHead .clkNav .navComp > ul li .langSelector ul.langList li {
  display: flex;
  color: #000000;
  align-items: center;
  margin-left: 0;
}
header.clkHead .clkNav .navComp > ul li .langSelector ul.langList li:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c2c2c2;
}
header.clkHead .clkNav .navComp > ul li .langSelector ul.langList li img {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
header.clkHead .clkNav .navComp > ul li .langSelector ul.langList li:hover, header.clkHead .clkNav .navComp > ul li .langSelector ul.langList li.active {
  color: #FAD32E;
}
header.clkHead .clkNav .navComp > ul li.navLi a.navLink:hover {
  color: #FAD32E;
}
header.clkHead .clkNav .navComp > ul.rightlist > li {
  margin-left: 20px;
}
header.clkHead.scrolled {
  background-color: #1C1B18;
  max-height: 80px;
}
header.clkHead.scrolled .clkNav {
  max-height: 80px;
}
header.clkHead.scrolled .clkNav .navLogo img {
  height: 80px;
}

#heroSection {
  position: relative;
}
#heroSection .carousel-item {
  background-color: #000000;
  position: relative;
}
#heroSection .carousel-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 33.28%;
  left: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), black);
  z-index: 1;
}
#heroSection .carousel-item img {
  height: 74.7vh;
  object-fit: cover;
  object-position: center;
  opacity: 0.5;
  min-height: 700px;
}
#heroSection .carousel-item .carouselText {
  color: #FFFFFF;
  position: absolute;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  place-content: center;
}
#heroSection .carousel-item .carouselText h1 {
  font-size: 5vw;
  text-align: center;
}
#heroSection .carousel-control-next, #heroSection .carousel-control-prev {
  overflow: hidden;
  opacity: 0.1;
  z-index: 1;
  width: 11vw;
  transition: 0.3s opacity ease;
}
#heroSection .carousel-control-next i, #heroSection .carousel-control-prev i {
  font-size: 13vw;
}
#heroSection .carousel-control-next:hover, #heroSection .carousel-control-prev:hover {
  opacity: 0.2;
}
#heroSection .carousel-control-next i {
  position: absolute;
  right: -20%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#heroSection .carousel-control-prev i {
  position: absolute;
  left: -20%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#heroSection .bannerText {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  color: #FFFFFF;
  z-index: 2;
  pointer-events: none;
}
#heroSection .bannerText h4 {
  font-family: "Montserrat", sans-serif;
}
#heroSection .bannerText .mainBtn {
  pointer-events: all;
}
#heroSection .carousel-indicators {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  padding: 27px 40px;
}
#heroSection .carousel-indicators button {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  opacity: 1;
  border: none;
  margin: 5px;
  transition: 0.5s all ease;
}
#heroSection .carousel-indicators button.active {
  width: 12px;
  height: 12px;
  background-color: #FAD32E;
}
#heroSection .socialList {
  list-style-type: none;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 2;
}
#heroSection .socialList li a {
  padding: 15px;
  display: inline-block;
  color: #FFFFFF;
}
#heroSection .socialList li a:hover {
  color: #FAD32E;
}

.mainBtn {
  padding: 10px 25px !important;
  display: inline-block;
  background-color: #FAD32E;
  color: #000000 !important;
  border-radius: 10px;
  text-decoration: none;
  width: auto;
  border: none;
  transition: 0.3s all ease;
  cursor: pointer;
}
.mainBtn:hover {
  background-color: #82712e;
  text-decoration: none;
  color: #FFFFFF !important;
}
.mainBtn.dark {
  background-color: #000000;
  color: #FFFFFF !important;
}
.mainBtn.dark:hover {
  background-color: #FFFFFF;
  color: #000000 !important;
}
.mainBtn.white {
  background-color: #FFFFFF;
  color: #000000 !important;
}
.mainBtn.white:hover {
  background-color: #000000;
  color: #FFFFFF !important;
}
.mainBtn.big {
  font-size: 24px;
}
.mainBtn.small {
  font-size: 12px;
  padding: 5px 15px !important;
}
.mainBtn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.altBtn {
  padding: 10px 25px !important;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF !important;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.3s all ease;
}
.altBtn:hover {
  background-color: #FFFFFF;
  color: #000000 !important;
  text-decoration: none;
}
.altBtn:hover::before {
  display: none;
}
.altBtn.small {
  font-size: 12px;
  padding: 5px 15px !important;
}
.altBtn.danger {
  color: #ff4d4d !important;
  border-color: #ff4d4d;
}
.altBtn.danger:hover {
  background-color: #ff4d4d;
  color: #FFFFFF !important;
}

.sectionPad {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.mainTitle {
  margin-bottom: 45px;
}
.mainTitle h2 {
  margin-bottom: 0;
  line-height: 1;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  color: #FFFFFF;
}
.mainTitle h2::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 10px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #FAD32E;
  border-radius: 5px;
}
.mainTitle.left h2 {
  text-align: left;
}
.mainTitle.left h2::after {
  left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.mainTitle.dark h2 {
  color: #000000;
}
.mainTitle.whiteLine h2::after {
  background-color: #FFFFFF;
}

#second {
  background: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#second #engagementModel,
#second #topTalents {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}
#second #engagementModel::before,
#second #topTalents::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 233px;
  width: 100%;
  background: linear-gradient(#000, transparent);
}
#second #engagementModel .glassCard,
#second #topTalents .glassCard {
  padding: 35px 30px 30px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 24px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
}
#second #engagementModel .glassCard:hover,
#second #topTalents .glassCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}
#second #engagementModel .glassCard h3,
#second #topTalents .glassCard h3 {
  font-size: 20px;
}
#second #engagementModel .glassCard p,
#second #topTalents .glassCard p {
  margin-bottom: 0;
}
#second #engagementModel .glassCard ul,
#second #topTalents .glassCard ul {
  padding-left: 15px;
  margin-bottom: 0;
}
#second #engagementModel .glassCard ul li::marker,
#second #topTalents .glassCard ul li::marker {
  color: #FAD32E;
}
#second #engagementModel .glassCard ul li:not(:last-child),
#second #topTalents .glassCard ul li:not(:last-child) {
  margin-bottom: 15px;
}
#second #engagementModel::before {
  display: none;
}
#second #wayWorking {
  background-color: rgba(0, 0, 0, 0.4);
}
#second #wayWorking .col-lg-6 .glassCard {
  padding: 18px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 36px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 3px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
#second #wayWorking .col-lg-6 .glassCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}
#second #wayWorking .col-lg-6 .glassCard .textSection {
  padding-left: 55px;
  text-align: right;
}
#second #wayWorking .col-lg-6 .glassCard .textSection h3 {
  font-size: 20px;
}
#second #wayWorking .col-lg-6 .glassCard .textSection p {
  margin-bottom: 0;
}
#second #wayWorking .col-lg-6:nth-child(even) .glassCard {
  flex-direction: row;
}
#second #wayWorking .col-lg-6:nth-child(even) .glassCard .textSection {
  padding-left: 0;
  padding-right: 55px;
  text-align: left;
}
#second #wayWorking .col-lg-6:first-child {
  margin-top: 110px;
}

.imgSq {
  position: relative;
  width: 100%;
  padding-top: 100%;
}
.imgSq img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}

.salaryCurrency {
  position: relative;
}
.salaryCurrency::before {
  content: "\F155";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  color: #000000;
  top: calc(50% - 8px);
  left: 17px;
}
.salaryCurrency input {
  padding-left: 40px !important;
}

#augmentation {
  background-color: #2D2D2D;
  background-image: url(/static/media/curve-bg.13b18c32.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #B2B2B2;
  position: relative;
}
#augmentation .col-lg-6 .col-md-6 .glassCard {
  padding: 20px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 24px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
}
#augmentation .col-lg-6 .col-md-6 .glassCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}
#augmentation .col-lg-6 .col-md-6 .glassCard .icon {
  width: 120px;
  height: 120px;
  font-size: 78px;
  display: grid;
  place-content: center;
  margin-bottom: 10px;
}
#augmentation .col-lg-6 .col-md-6 .glassCard .icon i {
  color: transparent;
  -webkit-text-stroke: 3px #FFFFFF;
}
#augmentation .col-lg-6 .col-md-6 .glassCard h3 {
  font-size: 20px;
  margin-bottom: 15px;
}
#augmentation .col-lg-6 .col-md-6 .glassCard p {
  margin-bottom: 0;
}
#augmentation .col-lg-6 .col-md-6:last-child {
  margin-top: 80px;
}
#augmentation .paw50 {
  position: absolute;
  width: calc(50vw - 8px);
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#augmentation .paw50 .splide__slide:nth-child(even),
#augmentation .paw50 .owl-item:nth-child(even) {
  margin-top: 115px;
}
#augmentation .paw50 .developerCard {
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}
#augmentation .paw50 .developerCard .imgSq {
  margin-bottom: 18px;
}
#augmentation .paw50 .developerCard .imgSq img {
  border-radius: 40px;
}
#augmentation .paw50 .developerCard .textSection {
  padding: 0 20px 15px;
}
#augmentation .paw50 .developerCard .textSection h4 {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 1;
}
#augmentation .paw50 .developerCard .textSection span {
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1;
}

#ipSection {
  background: url(/static/media/your_ipsafe.dc480b60.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  text-align: center;
}
#ipSection::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
}
#ipSection::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(#2D2D2D);
}
#ipSection .container {
  position: relative;
}
#ipSection .container h2 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
#ipSection .deel {
  position: absolute;
  right: 22px;
  bottom: 22px;
  opacity: 0.2;
}

.smallSectionPad {
  padding-top: 80px;
  padding-bottom: 80px;
}

#offShore {
  color: #000000;
  background-color: #F5F5F5;
  position: relative;
}
#offShore::before {
  content: "";
  background: url(/static/media/offshore-bg.0da61f15.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  top: 0;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(to right, transparent 100px, black 200px);
          mask-image: linear-gradient(to right, transparent 100px, black 200px);
  opacity: 0.1;
}
#offShore .whiteCard {
  text-align: center;
  padding: 20px;
  margin-bottom: 24px;
  background-color: #FFFFFF;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 1;
}
#offShore .whiteCard i {
  font-size: 50px;
  margin-bottom: 20px;
  color: transparent;
  -webkit-text-stroke: 3px #FAD32E;
}
#offShore .whiteCard h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #000000;
}
#offShore .whiteCard p {
  margin-bottom: 0;
  color: #000000;
}
#offShore .img-fill {
  width: 100%;
  height: calc(100% - 24px);
  object-fit: cover;
  object-position: center;
  border-radius: 80px;
}

#howToHire {
  background-color: #2D2D2D;
  position: relative;
  z-index: 1;
}
#howToHire::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 165px;
  max-height: 50%;
  top: 56%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #82712E;
  z-index: -1;
}
#howToHire .col-lg-4 {
  position: relative;
}
#howToHire .col-lg-4::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 5px;
}
#howToHire .col-lg-4::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #FFFFFF;
  top: -5px;
}
#howToHire .col-lg-4:first-child::after {
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  left: 50%;
}
#howToHire .col-lg-4:last-child::after {
  width: 50%;
}
#howToHire .col-lg-4 .glassCard {
  padding: 90px 40px 50px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-top: 35px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
}
#howToHire .col-lg-4 .glassCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}
#howToHire .col-lg-4 .glassCard h3 {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 15px;
}
#howToHire .col-lg-4 .glassCard p {
  font-size: 14px;
  margin-bottom: 0;
}
#howToHire .col-lg-4 .glassCard::before {
  content: attr(data-num);
  text-align: right;
  font-size: 200px;
  font-family: besom;
  opacity: 0.1;
  color: #000;
  line-height: 1;
  position: absolute;
  right: 20px;
  top: 20px;
}
#howToHire.guideline {
  background-color: #1C1B18;
}
#howToHire.guideline .col-lg-4 {
  display: grid;
}
#howToHire.guideline .col-lg-4::before, #howToHire.guideline .col-lg-4::after {
  display: none;
}
#howToHire.guideline .col-lg-4 .glassCard {
  margin-top: 0;
  margin-bottom: 24px;
}
#howToHire.guideline .col-lg-4 .glassCard p {
  margin-bottom: 0;
}

#joinTalent {
  background: url(/static/media/join-community.7ad04d7d.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  text-align: center;
}
#joinTalent::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #2D2D2D;
  opacity: 0.85;
  z-index: -1;
}
#joinTalent::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(#2D2D2D, transparent);
}
#joinTalent .container {
  position: relative;
}
#joinTalent .container h2 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}

#testimonial {
  background: url(/static/media/testmonials.c6489f9b.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  color: #FFFFFF;
}
#testimonial::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 25%;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #000, transparent);
  opacity: 0.42;
  z-index: -1;
}
#testimonial::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 25%;
  left: 0;
  bottom: 0;
  background: linear-gradient(to top, #2D2D2D, transparent);
  opacity: 0.5;
  z-index: -1;
}
#testimonial > .sectionPad {
  background-color: rgba(0, 0, 0, 0.5);
}
#testimonial .glassCard {
  padding: 50px 30px 30px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-top: 35px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#testimonial .glassCard > p {
  margin-bottom: 16px;
}
#testimonial .glassCard::before {
  content: "\F10D";
  font-size: 134px;
  position: absolute;
  left: -20px;
  top: -27px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #FAD32E;
  opacity: 0.25;
  z-index: -1;
}
#testimonial .glassCard .userDetails {
  display: flex;
}
#testimonial .glassCard .userDetails img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}
#testimonial .glassCard .userDetails .textSection {
  display: grid;
  align-items: center;
  align-content: center;
}
#testimonial .glassCard .userDetails .textSection h4 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  color: #FFFFFF;
}
#testimonial .glassCard .userDetails .textSection p {
  font-weight: 700;
  color: #FAD32E;
  letter-spacing: 3px;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
}

#blog {
  background-color: #2D2D2D;
  background-image: url(/static/media/blog-bg.4a870d4c.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#blog.abt {
  background-image: none;
  position: relative;
}
#blog.abt::after {
  content: "";
  position: absolute;
  height: 233px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #1C1B18, transparent);
}
#blog.abt .container {
  position: relative;
  z-index: 1;
}

.blogCard {
  color: #FFFFFF;
  border-radius: 40px;
  display: block;
  text-decoration: none;
  margin-bottom: 35px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
}
.blogCard:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.blogCard .imgSection {
  position: relative;
  width: 100%;
  height: 215px;
  overflow: hidden;
  border-radius: 40px;
}
.blogCard .imgSection img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
}
.blogCard .imgSection::after {
  content: "";
  width: 20px;
  height: 20px;
  right: 15px;
  bottom: 15px;
  position: absolute;
  background-color: #FAD32E;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
.blogCard .textSection {
  padding: 20px;
}
.blogCard .textSection > h3 {
  font-size: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.blogCard .textSection > p {
  color: #AAAAAA;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.blogCard .textSection ul.blogTags {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
}
.blogCard .textSection ul.blogTags li {
  font-size: 10px;
  font-weight: 700;
  color: #FAD32E;
  padding: 2px 5px;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.blogCard .textSection .blogFooter {
  border-top: 1px solid #707070;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.blogCard .textSection .blogFooter .author {
  display: flex;
}
.blogCard .textSection .blogFooter .author img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}
.blogCard .textSection .blogFooter .author .name {
  display: grid;
  align-items: center;
  align-content: center;
}
.blogCard .textSection .blogFooter .author .name p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #FFFFFF;
}
.blogCard .textSection .blogFooter .author .name h4 {
  font-weight: 700;
  color: #FAD32E;
  letter-spacing: 3px;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.blogCard .textSection .blogFooter .dateBlog {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #FFFFFF;
}
.blogCard .textSection .blogFooter .dateBlog i {
  color: #FAD32E;
  margin-right: 10px;
}
.blogCard:hover {
  color: #FFFFFF;
}

#joinUs {
  position: relative;
  background-color: #000;
  color: #fff;
}
#joinUs::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}
#joinUs::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 110px;
  background-color: #F5F5F5;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
  bottom: -1px;
  right: 0;
}
#joinUs img.divider {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: -1px;
  z-index: 2;
}
#joinUs .sectionPad {
  position: relative;
}
#joinUs .logo-bg {
  opacity: 0.1;
  -webkit-filter: invert(1);
          filter: invert(1);
}

#bookAppointment {
  color: #AAAAAA;
  background-color: #F5F5F5;
}
#bookAppointment img {
  max-width: 212px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 25px;
}

#partner h2 {
  text-align: center;
  font-size: 24px;
}
#partner .partner {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
#partner .partner img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  opacity: 1;
  transition: 0.3s all ease;
}
#partner .partner img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

footer {
  background-color: #1C1B18;
}
footer .topFooter {
  padding-top: 90px;
  padding-bottom: 60px;
  color: #AAAAAA;
}
footer .topFooter .col-lg-6 img {
  margin-bottom: 15px;
}
footer .topFooter .col-lg-6 p {
  margin-bottom: 15px;
}
footer .topFooter .col-lg-6 form.subscribe {
  display: flex;
  width: 100%;
  background-color: #2D2D2D;
  border: 1px solid #FAD32E;
  border-radius: 10px;
  margin-bottom: 25px;
}
footer .topFooter .col-lg-6 form.subscribe input {
  border: none;
  border-radius: 10px;
  padding: 15px 18px;
  line-height: 1;
  width: 100%;
  color: #FFFFFF;
  background-color: transparent;
}
footer .topFooter .col-lg-6 form.subscribe input::-webkit-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
footer .topFooter .col-lg-6 form.subscribe input:-ms-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
footer .topFooter .col-lg-6 form.subscribe input::placeholder {
  font-style: italic;
  color: #B2B2B2;
}
footer .topFooter .col-lg-6 form.subscribe button {
  font-size: 0;
  color: transparent;
  width: 65px;
  background-color: #FAD32E;
  border: none;
  border-radius: 10px 8px 8px 10px;
}
footer .topFooter .col-lg-6 form.subscribe button i {
  font-size: 21px;
  color: #000000;
  transition: 0.3s color ease;
}
footer .topFooter .col-lg-6 form.subscribe button:hover i {
  color: #FFFFFF;
}
footer .topFooter .col-lg-6 ul.conDetails {
  list-style-type: none;
  padding-left: 0;
}
footer .topFooter .col-lg-6 ul.conDetails li {
  display: flex;
  margin-bottom: 10px;
}
footer .topFooter .col-lg-6 ul.conDetails li i {
  color: #FAD32E;
  margin-right: 10px;
  padding-top: 3px;
  font-size: 19px;
  width: 20px;
}
footer .topFooter .col-lg-6 ul.conDetails li p {
  margin-bottom: 0;
}
footer .topFooter .col-lg-6 ul.conDetails li a {
  text-decoration: none;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
}
footer .topFooter .col-lg-6 ul.conDetails li a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  transition: 0.3s width ease;
}
footer .topFooter .col-lg-6 ul.conDetails li a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
footer .topFooter .col-lg-6 ul.conDetails li a:hover::before {
  width: 100%;
}
footer .topFooter .col-lg-6 ul.conDetails li .addressFooter {
  display: flex;
}
footer .topFooter .col-lg-6 ul.conDetails li .addressFooter p span {
  color: #FFFFFF;
}
footer .topFooter .col-lg-6 ul.conDetails li .addressFooter .line {
  width: 10%;
  flex: 0 0 10%;
  position: relative;
}
footer .topFooter .col-lg-6 ul.conDetails li .addressFooter .line::before {
  content: "";
  width: 1px;
  background-color: #E2E2E2;
  height: 100%;
  margin: auto;
  display: block;
}
footer .topFooter .col-lg-6 ul.socialList {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
}
footer .topFooter .col-lg-6 ul.socialList li {
  margin-right: 10px;
}
footer .topFooter .col-lg-6 ul.socialList li a {
  width: 40px;
  height: 40px;
  background-color: #FAD32E;
  color: #000000;
  display: grid;
  place-content: center;
  border-radius: 20px;
  text-decoration: none;
  transition: 0.3s all ease;
}
footer .topFooter .col-lg-6 ul.socialList li a:hover {
  background-color: #82712E;
  color: #FFFFFF;
  text-decoration: none;
}
footer .topFooter .col-lg-6 .listLinks {
  display: flex;
  flex-wrap: wrap;
}
footer .topFooter .col-lg-6 .listLinks .footerList h3 {
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 20px;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li {
  margin-bottom: 20px;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li:last-child {
  margin-bottom: 0;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li a {
  text-decoration: none;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  transition: 0.3s width ease;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
footer .topFooter .col-lg-6 .listLinks .footerList ul li a:hover::before {
  width: 100%;
}
footer .topFooter .col-lg-6 .listLinks .vLine {
  display: grid;
  width: 1px;
  background-color: #707070;
  margin-left: 65px;
  margin-right: 65px;
}
footer .bottomFooter {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000000;
}
footer .bottomFooter p {
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: #AAAAAA;
  margin-bottom: 0;
}

.bgOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: none;
  place-content: center;
}
.bgOverlay.active {
  display: grid;
}
.bgOverlay .popupCard {
  background-color: #1C1B18;
  border-radius: 50px;
  padding: 60px;
  color: #FFFFFF;
  position: relative;
  width: calc(100vw - 48px);
  max-width: 500px;
}
.bgOverlay .popupCard .maxHeight {
  max-height: calc(90vh - 180px);
  overflow: auto;
  padding-right: 4px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.bgOverlay .popupCard .maxHeight::-webkit-scrollbar {
  width: 1px;
}
.bgOverlay .popupCard .maxHeight::-webkit-scrollbar-track {
  background: transparent;
}
.bgOverlay .popupCard .maxHeight::-webkit-scrollbar-thumb {
  background: #FAD32E;
}
.bgOverlay .popupCard .maxHeight::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF;
}
.bgOverlay.big .popupCard {
  max-width: 1000px;
}
.bgOverlay .closeBtn {
  width: 50px;
  height: 50px;
  background-color: #2D2D2D;
  color: #FAD32E;
  border: none;
  border-radius: 25px;
  position: absolute;
  right: 25px;
  top: 25px;
  transition: 0.3s all ease;
}
.bgOverlay .closeBtn:hover {
  background-color: #000000;
}

.zForm .inpGroup {
  margin-bottom: 10px;
}
.zForm .inpGroup input[type=text], .zForm .inpGroup input[type=number], .zForm .inpGroup input[type=password], .zForm .inpGroup input[type=email], .zForm .inpGroup input[type=tel], .zForm .inpGroup input[type=search], .zForm .inpGroup input[type=file], .zForm .inpGroup textarea, .zForm .inpGroup .react-tags__search-input {
  -webkit-appearance: none;
          appearance: none;
  border: none;
  display: block;
  width: 100% !important;
  border: none;
  border-radius: 10px;
  padding: 15px 18px;
  line-height: 1;
  height: auto;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  background-color: #2D2D2D;
}
.zForm .inpGroup input[type=text]::-webkit-input-placeholder, .zForm .inpGroup input[type=number]::-webkit-input-placeholder, .zForm .inpGroup input[type=password]::-webkit-input-placeholder, .zForm .inpGroup input[type=email]::-webkit-input-placeholder, .zForm .inpGroup input[type=tel]::-webkit-input-placeholder, .zForm .inpGroup input[type=search]::-webkit-input-placeholder, .zForm .inpGroup input[type=file]::-webkit-input-placeholder, .zForm .inpGroup textarea::-webkit-input-placeholder, .zForm .inpGroup .react-tags__search-input::-webkit-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup input[type=text]:-ms-input-placeholder, .zForm .inpGroup input[type=number]:-ms-input-placeholder, .zForm .inpGroup input[type=password]:-ms-input-placeholder, .zForm .inpGroup input[type=email]:-ms-input-placeholder, .zForm .inpGroup input[type=tel]:-ms-input-placeholder, .zForm .inpGroup input[type=search]:-ms-input-placeholder, .zForm .inpGroup input[type=file]:-ms-input-placeholder, .zForm .inpGroup textarea:-ms-input-placeholder, .zForm .inpGroup .react-tags__search-input:-ms-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup input[type=text]::placeholder, .zForm .inpGroup input[type=number]::placeholder, .zForm .inpGroup input[type=password]::placeholder, .zForm .inpGroup input[type=email]::placeholder, .zForm .inpGroup input[type=tel]::placeholder, .zForm .inpGroup input[type=search]::placeholder, .zForm .inpGroup input[type=file]::placeholder, .zForm .inpGroup textarea::placeholder, .zForm .inpGroup .react-tags__search-input::placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup input[type=text]:focus, .zForm .inpGroup input[type=number]:focus, .zForm .inpGroup input[type=password]:focus, .zForm .inpGroup input[type=email]:focus, .zForm .inpGroup input[type=tel]:focus, .zForm .inpGroup input[type=search]:focus, .zForm .inpGroup input[type=file]:focus, .zForm .inpGroup textarea:focus, .zForm .inpGroup .react-tags__search-input:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(250, 211, 46, 0.25);
}
.zForm .inpGroup > label {
  display: block;
  font-weight: 700;
}
.zForm .inpGroup > small {
  color: #777;
}
.zForm .inpGroup select {
  -webkit-appearance: none;
          appearance: none;
  border: none;
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 15px 18px;
  line-height: 1;
  color: #FFFFFF;
  background-color: #2D2D2D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='%23fff' data-testid='ArrowDropDownIcon'%3E%3Cpath d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 24px;
  background-position: calc(100% - 15px);
  background-repeat: no-repeat;
}
.zForm .inpGroup .phoneNumber {
  display: flex;
}
.zForm .inpGroup .phoneNumber select {
  width: 128px;
  max-width: 80px;
  margin-right: 12px;
}
.zForm .inpGroup .react-tel-input {
  display: flex;
  flex-direction: row-reverse;
}
.zForm .inpGroup .react-tel-input .flag-dropdown {
  position: relative;
  margin-right: 24px;
  border: none;
  background-color: #2D2D2D;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.zForm .inpGroup .react-tel-input .flag-dropdown .country-list {
  left: 0;
  top: 100%;
  margin: 0;
  margin-top: 5px;
  border-radius: 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.zForm .inpGroup .react-tel-input .flag-dropdown .country-list::-webkit-scrollbar {
  display: none;
}
.zForm .inpGroup .react-tel-input .flag-dropdown .country-list .country-name {
  color: #AAAAAA;
}
.zForm .inpGroup .react-tel-input .flag-dropdown .selected-flag,
.zForm .inpGroup .react-tel-input .flag-dropdown .selected-flag:focus,
.zForm .inpGroup .react-tel-input .flag-dropdown .selected-flag:hover {
  background-color: transparent !important;
}
.zForm .inpGroup.dateGrp {
  display: flex;
  flex-wrap: wrap;
}
.zForm .inpGroup.dateGrp label {
  width: 100%;
}
.zForm .inpGroup.dateGrp .year {
  width: 100px;
  flex: 0 0 100px;
  margin-right: 24px;
}
.zForm .inpGroup.dateGrp .month {
  width: calc(100% - 124px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 124px);
}
.zForm .inpGroup.dateGrp.disable {
  pointer-events: none;
  opacity: 0.3;
}
.zForm .inpGroup .fileinp {
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
  z-index: -9;
}
.zForm .inpGroup .fileInpWrapper {
  position: relative;
}
.zForm .inpGroup .file-inp-l {
  background-color: #2D2D2D;
  padding: 13px 18px 12px 9px;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  color: #FFFFFF;
  border: none;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  background-color: #2D2D2D;
  color: #FFFFFF;
  border-radius: 10px;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary {
  font-family: "Montserrat", sans-serif;
  -webkit-transform: translate(18px, 12px);
          transform: translate(18px, 12px);
  font-style: italic;
  color: #b2b2b2;
  z-index: 1;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon label + .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon label + .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
  margin-top: 8px;
  margin-bottom: 9px;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon label + .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon label + .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart {
  margin-top: 5px;
  margin-bottom: 5px;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl:after,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot:before,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl:after,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot:before {
  display: none;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment {
  right: 15px;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment button,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment button {
  color: #FFFFFF;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiChip-label.MuiChip-labelMedium,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiChip-label.MuiChip-labelMedium {
  font-family: "Montserrat", sans-serif;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-left: 5px;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input {
  padding: 4px 0 5px;
  padding-left: 18px;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
  width: auto !important;
  height: auto;
  line-height: normal;
  line-height: initial;
  display: unset;
  -webkit-appearance: unset;
          appearance: unset;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input::-webkit-input-placeholder, .zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input::-webkit-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input:-ms-input-placeholder, .zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input:-ms-input-placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input::placeholder,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root .MuiInput-input::placeholder {
  font-style: italic;
  color: #B2B2B2;
}
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-tag,
.zForm .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-tag {
  background-color: #FAD32E;
  color: #000000;
}
.zForm .inpGroup .clockCheck > input {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -9;
  opacity: 0;
}
.zForm .inpGroup .clockCheck > input + label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.zForm .inpGroup .clockCheck > input + label .iconCheckz {
  width: 29px;
  height: 29px;
  margin: 10px;
  margin-right: 5px;
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: transparent;
  border-radius: 15px;
  transition: 0.3s all ease;
}
.zForm .inpGroup .clockCheck > input + label:hover .iconCheckz {
  background-color: rgba(0, 0, 0, 0.5);
}
.zForm .inpGroup .clockCheck > input:checked + label .iconCheckz {
  background-color: #FAD32E;
  color: #000;
}
.zForm.white .inpGroup input[type=text], .zForm.white .inpGroup input[type=number], .zForm.white .inpGroup input[type=password], .zForm.white .inpGroup input[type=email], .zForm.white .inpGroup input[type=tel], .zForm.white .inpGroup input[type=search], .zForm.white .inpGroup input[type=file], .zForm.white .inpGroup textarea, .zForm.white .inpGroup .react-tags__search-input {
  background-color: #FFFFFF;
  color: #000000;
}
.zForm.white .inpGroup > label {
  color: #FFFFFF;
}
.zForm.white .inpGroup select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='%23000' data-testid='ArrowDropDownIcon'%3E%3Cpath d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E");
  background-color: #FFFFFF;
  color: #000000;
}
.zForm.white .inpGroup .file-inp-l {
  background-color: #FFFFFF;
  color: #000000;
}
.zForm.white .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon,
.zForm.white .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  background-color: #FFFFFF;
  color: #000000;
}
.zForm.white .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment button,
.zForm.white .inpGroup .skillsTags.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment button {
  color: #000;
}
.zForm.white .inpGroup .clockCheck > input + label .iconCheckz {
  background-color: rgba(255, 255, 255, 0.3);
}
.zForm.white .inpGroup .clockCheck > input + label:hover .iconCheckz {
  background-color: rgba(255, 255, 255, 0.5);
}
.zForm.white .inpGroup .clockCheck > input:checked + label .iconCheckz {
  background-color: #FAD32E;
  color: #000000;
}
.zForm.white .inpGroup > small {
  color: #ccc;
}
.zForm.white .inpGroup .react-tel-input .flag-dropdown {
  background-color: #FFFFFF;
}

.MuiAutocomplete-popper > * {
  font-family: "Montserrat", sans-serif;
}

#topInnerPage {
  padding-top: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
#topInnerPage > * {
  position: relative;
  z-index: 1;
}
#topInnerPage::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.45;
  left: 0;
  top: 0;
}

#topInnerPage.blogAll {
  background-color: #2D2D2D;
  background-image: url(/static/media/blog-bg.4a870d4c.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#topInnerPage.contact {
  background-image: url(/static/media/city.e925f816.jpg);
  position: relative;
  z-index: 1;
}
#topInnerPage.contact::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 233px;
  background: linear-gradient(to top, #1C1B18, transparent);
}
#topInnerPage.contact iframe {
  width: 100%;
  height: 370px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.thankyou {
  background-color: #000000;
  color: #FFFFFF;
}

.glassSection {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 30px;
  border-radius: 40px;
}

.addrDetails {
  display: flex;
  margin-bottom: 7px;
  color: #FFFFFF;
}
.addrDetails i {
  font-size: 36px;
  color: #FAD32E;
  flex: 0 0 47px;
  width: 47px;
  height: 47px;
  margin-right: 10px;
  display: grid;
  place-content: center;
}
.addrDetails p {
  margin-bottom: 0;
}
.addrDetails a {
  text-decoration: none;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
}
.addrDetails a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  transition: 0.3s width ease;
}
.addrDetails a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.addrDetails a:hover::before {
  width: 100%;
}

#topInnerPage.whyUs {
  background-image: url(/static/media/why-1.eb000b6e.jpg);
}
#topInnerPage.whyUs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 233px;
  background: linear-gradient(to top, #1C1B18, transparent);
}
#topInnerPage.whyUs .glassCard {
  padding: 35px 30px 30px;
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 24px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: 0.3s all ease;
}
#topInnerPage.whyUs .glassCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}
#topInnerPage.whyUs .glassCard img {
  margin-bottom: 10px;
}
#topInnerPage.whyUs .glassCard h3 {
  font-size: 20px;
}
#topInnerPage.whyUs .glassCard p {
  margin-bottom: 0;
}

#hireTop {
  background-color: #1C1B18;
  background-image: url(/static/media/wavy-lines.d9c12ef2.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFFFFF;
  position: relative;
}
#hireTop hr {
  background-color: #393939;
  margin: 0;
  opacity: 1;
}
#hireTop #partner .partner {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
#hireTop::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 233px;
  background: linear-gradient(to bottom, #1C1B18, transparent);
  z-index: 1;
}

#screening {
  background-image: url(/static/media/why-2.9b6d4d7d.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: #FFFFFF;
}
#screening > * {
  position: relative;
  z-index: 1;
}
#screening::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.45;
  left: 0;
  top: 0;
}
#screening::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 233px;
  background: linear-gradient(to top, #1C1B18, transparent);
}
#screening #top3percent {
  counter-reset: top;
}
#screening #top3percent .row:not(:last-child) {
  margin-bottom: 24px;
}
#screening #top3percent .row:not(:last-child) .col-lg-2.align-items-center {
  position: relative;
}
#screening #top3percent .row:not(:last-child) .col-lg-2.align-items-center::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: calc(50% - 1px);
  background-color: #FAD32E;
  top: 50%;
}
#screening #top3percent .icon {
  width: 125px;
  height: 125px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 2px solid #FAD32E;
  display: grid;
  place-content: center;
  border-radius: 50%;
}
#screening #top3percent .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
}
#screening #top3percent .glassCard.percent {
  padding: 20px;
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;
}
#screening #top3percent .glassCard.percent h4 {
  font-size: 40px;
  font-weight: 100;
  color: #FFFFFF;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
}
#screening #top3percent .glassCard.percent p {
  color: #FAD32E;
  margin-bottom: 0;
}
#screening #top3percent .glassCard.desc {
  padding: 90px 40px 40px;
  position: relative;
}
#screening #top3percent .glassCard.desc h3 {
  font-size: 20px;
  margin-bottom: 15px;
}
#screening #top3percent .glassCard.desc p {
  font-size: 14px;
  margin-bottom: 0;
}
#screening #top3percent .glassCard.desc::before {
  counter-increment: top;
  content: counter(top);
  position: absolute;
  font-size: 100px;
  font-family: "besom";
  color: #FAD32E;
  right: 30px;
  top: 30px;
  line-height: 1;
  opacity: 0.1;
}

#aboutUs {
  background-image: url(/static/media/about.a2de56a2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#aboutUs #topInnerPage {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.5);
}
#aboutUs #topInnerPage::before {
  display: none;
}
#aboutUs #topInnerPage .glassCard {
  border-radius: 40px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 30px;
  color: #FFFFFF;
}
#aboutUs #topInnerPage .glassCard i {
  color: transparent;
  -webkit-text-stroke: #FAD32E 3px;
  font-size: 64px;
  width: 100px;
  height: 100px;
  display: grid;
  place-content: center;
}
#aboutUs #ourTeam {
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
}
#aboutUs #ourTeam::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 233px;
  background: linear-gradient(to top, #2D2D2D, transparent);
  bottom: 0;
  left: 0;
}
#aboutUs #ourTeam > .container {
  position: relative;
  z-index: 1;
}
#aboutUs #ourTeam .developerCard {
  color: #FFFFFF;
  border-radius: 40px;
  margin-bottom: 24px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 24px;
}
#aboutUs #ourTeam .developerCard .imgSq {
  margin-bottom: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
}
#aboutUs #ourTeam .developerCard .imgSq img {
  border-radius: 40px;
  transition: 0.3s all ease;
}
#aboutUs #ourTeam .developerCard .imgSq ul.socialList {
  position: absolute;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}
#aboutUs #ourTeam .developerCard .imgSq ul.socialList li {
  margin: 5px;
  position: relative;
  top: 10px;
  opacity: 0;
  transition: 0.3s all ease;
}
#aboutUs #ourTeam .developerCard .imgSq ul.socialList li a {
  width: 35px;
  height: 35px;
  display: grid;
  place-content: center;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 18px;
  transition: 0.3s all ease;
}
#aboutUs #ourTeam .developerCard .imgSq ul.socialList li a:hover {
  background-color: #FAD32E;
  color: #000000;
}
#aboutUs #ourTeam .developerCard .textSection {
  padding: 0 20px 15px;
}
#aboutUs #ourTeam .developerCard .textSection h4 {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 1;
}
#aboutUs #ourTeam .developerCard .textSection span {
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1;
}
#aboutUs #ourTeam .developerCard:hover .imgSq img {
  opacity: 0.5;
}
#aboutUs #ourTeam .developerCard:hover .imgSq ul.socialList {
  opacity: 1;
  visibility: visible;
}
#aboutUs #ourTeam .developerCard:hover .imgSq ul.socialList li {
  top: 0;
  opacity: 1;
}
#aboutUs #ourTeam .developerCard:hover .imgSq ul.socialList li:nth-child(2) {
  transition-delay: 0.2s;
}
#aboutUs #ourTeam .developerCard:hover .imgSq ul.socialList li:nth-child(3) {
  transition-delay: 0.4s;
}

#topInnerPage.career {
  background-image: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  position: relative;
}
#topInnerPage.career .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  padding: 30px;
}
#topInnerPage.career::after {
  content: "";
  height: 233px;
  width: 100%;
  background: linear-gradient(to top, #1C1B18, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
}
#topInnerPage.career .jobCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  padding: 30px;
  color: #FFFFFF;
  position: relative;
  margin-bottom: 24px;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease;
}
#topInnerPage.career .jobCard:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.25);
}
#topInnerPage.career .jobCard .jobHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
#topInnerPage.career .jobCard .jobId {
  background-color: #000000;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #FAD32E;
  height: auto;
  white-space: nowrap;
  margin-left: 5px;
}
#topInnerPage.career .jobCard h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
#topInnerPage.career .jobCard p.place {
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: 700;
  color: #FAD32E;
}
#topInnerPage.career .jobCard p.remote {
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#topInnerPage.career .jobCard p.price {
  display: flex;
  margin-bottom: 0;
  line-height: 1;
}
#topInnerPage.career .jobCard p.price i {
  color: #FAD32E;
  margin-right: 10px;
}
#topInnerPage.career .jobCard .bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}
#topInnerPage.career .jobCard .textContent {
  color: #E2E2E2;
}
#topInnerPage.career .jobCard .textContent h3 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #FFFFFF;
}
#topInnerPage.career .jobCard .textContent ul li::marker {
  background-color: #FAD32E;
  color: #FAD32E;
}
#topInnerPage.career .jobCard .shareList {
  list-style-type: none;
  display: flex;
  color: #FFFFFF;
}
#topInnerPage.career .jobCard .shareList li {
  margin-right: 20px;
}
#topInnerPage.career .jobCard .shareList li a {
  color: #FFFFFF;
  text-decoration: none;
}
#topInnerPage.career .jobCard .shareList li a:hover {
  color: #FAD32E;
}

#topInnerPage.error404,
#topInnerPage.hireFromUs {
  background-image: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  position: relative;
}
#topInnerPage.error404 .glassCard,
#topInnerPage.hireFromUs .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-root,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-root {
  width: 100%;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-root *,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-root * {
  font-family: "Montserrat", sans-serif;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root {
  width: 100%;
  padding: 6px;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
  height: 49px;
  border: none;
  background-color: #fff;
  border-radius: 10px;
  color: #000000;
  top: 0;
  z-index: -1;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline legend,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-root .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline legend {
  display: none;
}
#topInnerPage.error404 .glassCard .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal,
#topInnerPage.hireFromUs .glassCard .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
  border-radius: 10px !important;
}
#topInnerPage.error404::after,
#topInnerPage.hireFromUs::after {
  content: "";
  height: 233px;
  width: 100%;
  background: linear-gradient(to top, #2D2D2D, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
}

#topInnerPage.error404::after {
  background: linear-gradient(to top, #1C1B18, transparent);
}

.tabWrapper ul.tabList {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tabWrapper ul.tabList li {
  padding: 25px 30px;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 1.2;
  cursor: pointer;
  opacity: 1;
}
.tabWrapper ul.tabList li:hover {
  opacity: 0.7;
}
.tabWrapper ul.tabList li.active {
  color: #FAD32E;
  font-weight: 700;
  position: relative;
}
.tabWrapper ul.tabList li.active::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 5px;
  background-color: #FAD32E;
  border-radius: 5px 5px 0 0;
  bottom: 0;
  left: calc(50% - 12.5px);
}
.tabWrapper .tabDiv {
  padding: 30px;
  display: none;
  color: #FFFFFF;
}
.tabWrapper .tabDiv.active {
  display: block;
}

.normalHead {
  font-family: "Montserrat", sans-serif;
}

#developers {
  background-color: #2D2D2D;
}
#developers .glassCard {
  padding: 30px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  display: flex;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}
#developers .glassCard .imgSection img {
  width: 312px;
  height: 312px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}
#developers .glassCard .textSection {
  padding-left: 30px;
  padding-right: 30px;
  color: #AAAAAA;
}
#developers .glassCard .textSection h3 {
  color: #FFFFFF;
  margin-bottom: 10px;
}
#developers .glassCard .textSection .developer {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 4px;
  color: #FAD32E;
}
#developers .glassCard .textSection p.remote {
  font-size: 12px;
  color: #AAAAAA;
  margin-bottom: 10px;
}
#developers .glassCard .textSection .iconDiv {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
  display: flex;
}
#developers .glassCard .textSection .iconDiv li {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 12px;
}
#developers .glassCard .textSection .iconDiv li i {
  margin-right: 12px;
  color: #FAD32E;
  font-size: 16px;
}
#developers .glassCard .textSection ul.skills {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
#developers .glassCard .textSection ul.skills li {
  margin-right: 10px;
  border-radius: 5px;
  height: auto;
}
#developers .glassCard .textSection ul.skills li a {
  background-color: #FAD32E;
  line-height: 5;
  padding: 5px;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  height: auto;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  border-radius: 5px;
}

#calendar-widget-popup-button-container {
  z-index: 1040;
}

#calendar-widget-popup-button-container #calendar-widget-popup-button {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  padding: 10px 25px !important;
  display: inline-block;
  color: #000000 !important;
  border-radius: 10px;
  text-decoration: none;
  width: auto;
  border: none;
  transition: 0.3s all ease;
  cursor: pointer;
  text-transform: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#calendar-widget-popup-button-container #calendar-widget-popup-button:hover {
  background-color: #82712e !important;
  color: #FFFFFF !important;
}

.blogDetail {
  color: #777;
}
.blogDetail::before {
  display: none;
}
.blogDetail h1, .blogDetail h2 {
  color: #000;
}
.blogDetail img {
  display: block;
  width: 60%;
  height: auto;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
}

.CookieConsent {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1041 !important;
  padding-top: 32px;
  padding-bottom: 32px;
}
.CookieConsent p {
  margin-bottom: 0;
}
.CookieConsent a {
  color: #FFFFFF;
  text-decoration: none;
  position: relative;
}
.CookieConsent a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  right: auto;
  transition: 0.3s width ease;
}
.CookieConsent a:hover {
  text-decoration: none;
  color: #FFFFFF;
}
.CookieConsent a:hover::before {
  width: 0;
  right: 0;
  left: auto;
}
.CookieConsent button {
  padding: 10px 25px !important;
  background-color: #FAD32E !important;
  border-radius: 10px !important;
  transition: 0.3s all ease;
}
.CookieConsent button:hover {
  background-color: #82712e !important;
  color: #FFFFFF m !important;
}

.backBtn {
  background-color: rgba(226, 226, 226, 0.5);
  color: #000000;
  font-size: 14px;
  text-decoration: none;
  line-height: 1;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 10px;
  transition: 0.3s background-color ease;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.backBtn i {
  font-size: 26px;
  margin-right: 10px;
  transition: 0.3s margin ease;
}
.backBtn:hover {
  background-color: #FAD32E;
  color: #000;
  text-decoration: none;
}
.backBtn:hover i {
  margin-right: 15px;
}

#topInnerPage.cookiePolicy {
  background-image: url(/static/media/cookie-bg.1c215ea2.jpg);
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: center;
}
#topInnerPage.cookiePolicy .glassSection {
  color: #E2E2E2;
}
#topInnerPage.cookiePolicy .glassSection h2 {
  color: #FFFFFF;
}
#topInnerPage.cookiePolicy .glassSection a {
  color: #FAD32E;
}
#topInnerPage.cookiePolicy .glassSection a:hover {
  text-decoration: none;
}
#topInnerPage.cookiePolicy .glassSection ol {
  counter-reset: item;
}
#topInnerPage.cookiePolicy .glassSection li {
  display: block;
}
#topInnerPage.cookiePolicy .glassSection li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  color: #ccc;
}

#topInnerPage.login {
  background-image: url(/static/media/login.8c6131c6.jpg);
}
#topInnerPage.login .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  margin-bottom: 24px;
}
#topInnerPage.login .glassCard .tabBtns {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#topInnerPage.login .glassCard .tabBtns .tabBtn {
  padding: 15px 35px;
  color: #FFFFFF;
  font-size: 22px;
  border: none;
  background-color: transparent;
  position: relative;
}
#topInnerPage.login .glassCard .tabBtns .tabBtn.active {
  font-size: 22px;
  color: #FAD32E;
  font-weight: 700;
}
#topInnerPage.login .glassCard .tabBtns .tabBtn.active::before {
  content: "";
  width: 25px;
  height: 5px;
  border-radius: 5px 5px 0 0;
  background-color: #FAD32E;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#topInnerPage.login .glassCard .tabDiv {
  padding: 30px;
  color: #FFFFFF;
  display: none;
}
#topInnerPage.login .glassCard .tabDiv.active {
  display: block;
}
#topInnerPage.login .glassCard .tabDiv .bottomSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
#topInnerPage.login .glassCard .tabDiv .bottomSection a {
  display: block;
  color: #C8C8C8;
  text-decoration: underline;
  font-weight: 700;
}
#topInnerPage.login .glassCard .tabDiv .hideBtn {
  opacity: 0;
  position: relative;
  z-index: 2;
}
#topInnerPage.login .glassCard .tabDiv .socialBtn {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  background-color: #F1F4F7;
  color: #000000;
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1;
  transition: 0.3s background-color ease;
}
#topInnerPage.login .glassCard .tabDiv .socialBtn.absolute-fill {
  position: absolute;
  width: calc(100% - var(--bs-gutter-x));
  left: calc(var(--bs-gutter-x) * 0.5);
  top: 0;
  pointer-events: none;
  z-index: 1;
}
#topInnerPage.login .glassCard .tabDiv .socialBtn span {
  padding-top: 3px;
  margin: auto;
}
#topInnerPage.login .glassCard .tabDiv .socialBtn:hover {
  background-color: #AAAAAA;
}
#topInnerPage.login .glassCard .tabDiv .signupText {
  margin-bottom: 0;
  margin-top: 25px;
}
#topInnerPage.login .glassCard .tabDiv .signupText button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #FAD32E;
  text-decoration: underline;
  font-weight: 700;
}
#topInnerPage.login .glassCard .tabDiv .areYou {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
#topInnerPage.login .glassCard .tabDiv .areYou li {
  margin-right: 30px;
  padding: 10px;
}
#topInnerPage.login .glassCard .tabDiv .areYou li label {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s opacity ease;
}
#topInnerPage.login .glassCard .tabDiv .areYou li label > * {
  pointer-events: none;
}
#topInnerPage.login .glassCard .tabDiv .areYou li label:hover {
  opacity: 0.7;
}
#topInnerPage.login .glassCard .tabDiv .areYou li label i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  display: grid;
  place-content: center;
  color: #000000;
  font-size: 22px;
}
#topInnerPage.login .glassCard .tabDiv .areYou li label i::before {
  opacity: 0;
  transition: 0.3s opacity ease;
}
#topInnerPage.login .glassCard .tabDiv .areYou li input[type=radio] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}
#topInnerPage.login .glassCard .tabDiv .areYou li input[type=radio]:checked + label i {
  background-color: #FAD32E;
}
#topInnerPage.login .glassCard .tabDiv .areYou li input[type=radio]:checked + label i::before {
  opacity: 1;
}
#topInnerPage.login .glassCard .tabDiv .signupForm {
  display: none;
}
#topInnerPage.login .glassCard .tabDiv .signupForm.active {
  display: block;
}

#topInnerPage.myAccountCandidate {
  background-image: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  position: relative;
}
#topInnerPage.myAccountCandidate .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  padding: 30px;
  color: #E2E2E2;
  margin-bottom: 24px;
}
#topInnerPage.myAccountCandidate .glassCard h4 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro h3 {
  color: #FFFFFF;
  font-size: 20px;
  margin-bottom: 10px;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro p.location {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro p.location i {
  font-size: 16px;
  color: transparent;
  -webkit-text-stroke: 1px #FAD32E;
  margin-right: 10px;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro p.designation {
  font-size: 12px;
  color: #FAD32E;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro ul.skills {
  padding-left: 0;
  margin-bottom: 10px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
#topInnerPage.myAccountCandidate .glassCard.userIntro ul.skills li {
  font-size: 12px;
  padding: 2px 5px;
  background-color: #FAD32E;
  color: #000000;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
}
#topInnerPage.myAccountCandidate .glassCard #profilePicInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
#topInnerPage.myAccountCandidate .glassCard .div85 {
  max-width: 85%;
  margin: auto;
  position: relative;
  margin-bottom: 40px;
}
#topInnerPage.myAccountCandidate .glassCard .div85 .imgSq img {
  border-radius: 50%;
}
#topInnerPage.myAccountCandidate .glassCard .div85 label#edit {
  display: grid;
  color: #000000;
  place-content: center;
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: #FAD32E;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s all ease;
}
#topInnerPage.myAccountCandidate .glassCard .div85 label#edit:hover {
  background-color: #82712e;
  color: #FFFFFF !important;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
#topInnerPage.myAccountCandidate .glassCard .userDetails p {
  color: #AAAAAA;
  margin-bottom: 0;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails p.sal i {
  color: #FAD32E;
  margin-right: 10px;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails a {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  position: relative;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  transition: 0.3s width ease;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails a:hover::before {
  width: 100%;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails .d-flex {
  justify-content: space-between;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails .d-flex h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails .d-flex p {
  color: #FAD32E;
  font-size: 12px;
  text-transform: uppercase;
  text-align: right;
}
#topInnerPage.myAccountCandidate .glassCard .userDetails .d-flex p.disabled {
  color: #AAAAAA;
}
#topInnerPage.myAccountCandidate::after {
  content: "";
  height: 233px;
  width: 100%;
  background: linear-gradient(to top, #1C1B18, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
}

button.mainBtn.okbtn {
  display: none;
}

.dNone {
  display: none;
}

#topInnerPage.editProfile {
  background-image: url(/static/media/pexels-laura-tancredi-7078666.a9c9c672.jpg);
  position: relative;
}
#topInnerPage.editProfile .glassCard {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 40px;
  padding: 30px;
  color: #E2E2E2;
  position: relative;
  z-index: 0;
}
#topInnerPage.editProfile .glassCard #profilePicInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
#topInnerPage.editProfile .glassCard .div85 {
  max-width: 312px;
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
}
#topInnerPage.editProfile .glassCard .div85 .imgSq img {
  border-radius: 50%;
}
#topInnerPage.editProfile .glassCard .div85 label#edit {
  display: grid;
  color: #000000;
  place-content: center;
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: #FAD32E;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s all ease;
}
#topInnerPage.editProfile .glassCard .div85 label#edit:hover {
  background-color: #82712e;
  color: #FFFFFF !important;
}
#topInnerPage.editProfile .glassCard h4 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
#topInnerPage.editProfile .glassCard #education .row, #topInnerPage.editProfile .glassCard #workexperience .row {
  padding-bottom: 15px;
}
#topInnerPage.editProfile .glassCard #education .row:not(:last-child), #topInnerPage.editProfile .glassCard #workexperience .row:not(:last-child) {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv input, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv label, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv label {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-weight: 700;
  justify-content: space-between;
  cursor: pointer;
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv label > *, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv label > * {
  pointer-events: none;
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv label span.toggleBtn, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv label span.toggleBtn {
  display: block;
  background-color: #5b5b5b;
  width: 30px;
  height: 10px;
  border-radius: 10px;
  position: relative;
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv label span.toggleBtn::before, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv label span.toggleBtn::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #AAAAAA;
  border-radius: 10px;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.3s all ease;
}
#topInnerPage.editProfile .glassCard #education .row .toggleBtnDiv input:checked + label span.toggleBtn::before, #topInnerPage.editProfile .glassCard #workexperience .row .toggleBtnDiv input:checked + label span.toggleBtn::before {
  left: 20px;
  background-color: #FAD32E;
}
#topInnerPage.editProfile .glassCard #skills button.altBtn {
  padding: 12px 25px 11px !important;
}
#topInnerPage.editProfile .glassCard #skills .MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0;
}
#topInnerPage.editProfile .glassCard #skills .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
#topInnerPage.editProfile .glassCard #skills .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
  padding: 0;
}
#topInnerPage.editProfile .glassCard .altBtn > * {
  pointer-events: none;
}
#topInnerPage.editProfile .glassCard #skillDivRow {
  overflow: visible;
}
#topInnerPage.editProfile .glassCard #skillDivRow .MuiAutocomplete-popper {
  z-index: 999 !important;
}

.z1 {
  z-index: 1 !important;
}

#resumeSuccess {
  display: none;
}
#resumeSuccess.active {
  display: block;
}

.toggleDiv {
  opacity: 0;
  height: 0px;
  overflow: hidden;
  transition: 0.3s all ease;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top;
          transform-origin: top;
}
.toggleDiv.active {
  opacity: 1;
  height: 100%;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.toggleDivBtn i {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}
.toggleDivBtn.active i {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.account-deletion {
  padding: 0 30px;
  display: flex;
}
.account-deletion button {
  line-height: 1;
  border: none;
  background-color: transparent;
  font-size: 12px;
  text-transform: uppercase;
  color: #AAAAAA;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}
.account-deletion button:hover {
  transition-delay: 0.3s;
  opacity: 1;
  color: #FAD32E;
}

.css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper {
  border-radius: 10px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium,
.css-89cdu0-MuiChip-root,
.css-iqd1hh-MuiButtonBase-root-MuiChip-root,
.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary,
.css-buw261-MuiAutocomplete-paper,
.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot,
.react-tel-input,
.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary {
  font-family: "Montserrat", sans-serif !important;
}

.react-tags__suggestions {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
}
.react-tags__suggestions ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
.react-tags__suggestions ul li span {
  padding: 10px;
  display: block;
}

.react-tags .react-tags__selected button {
  background-color: #FAD32E;
  color: #000000;
  padding: 5px 12px;
  font-size: 13px;
  border: none;
  margin: 5px;
  border-radius: 20px;
}

#heroTwinBanner {
  position: relative;
}
#heroTwinBanner .bannerWrapper {
  display: flex;
  flex-wrap: wrap;
  transition: 0.5s all ease;
}
#heroTwinBanner .bannerWrapper .bbanner {
  flex: 0 0 50%;
  position: relative;
  height: calc(100vh - 60px);
  transition: 0.5s all ease;
  overflow: hidden;
}
#heroTwinBanner .bannerWrapper .bbanner.active {
  flex: 0 0 100%;
}
#heroTwinBanner .bannerWrapper .bbanner.active .bannerText {
  width: 100%;
  height: 100%;
  display: grid;
  padding-top: 50px;
  padding-bottom: 50px;
}
#heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent {
  max-width: 50%;
}
#heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent .back {
  position: unset;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
#heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent .viewBtn {
  display: none;
}
#heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent p {
  max-width: 380px;
  margin: auto;
  margin-bottom: 20px;
  position: unset;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
#heroTwinBanner .bannerWrapper .bbanner.active.bannerLeft .bannerText .bannerContent {
  margin-right: 0;
}
#heroTwinBanner .bannerWrapper .bbanner.active.bannerRight .bannerText .bannerContent {
  margin-left: 0;
}
#heroTwinBanner .bannerWrapper .bbanner.hidden {
  flex: 0 0;
  opacity: 0;
  visibility: 0;
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText {
  position: absolute;
  background: linear-gradient(0deg, rgb(0, 0, 0), transparent);
  bottom: 0px;
  text-align: center;
  padding-top: 210px;
  padding-bottom: 80px;
  color: #fff;
  width: 100%;
  z-index: 2;
  transition: 0.5s all ease;
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent {
  max-width: 400px;
  width: 100%;
  margin: auto;
  padding: 10px;
  transition: 0.5s all ease;
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent .viewBtn {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent .viewBtn:hover {
  background-color: #2D2D2D;
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent .back,
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent p {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
}
#heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent button.back i {
  display: none;
}
#heroTwinBanner .bannerWrapper .bbanner img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s all ease;
}
#heroTwinBanner .bannerWrapper:hover .bbanner {
  flex: 0 0 45%;
}
#heroTwinBanner .bannerWrapper:hover .bbanner:hover {
  flex: 0 0 55%;
}
#heroTwinBanner .bannerWrapper:hover .bbanner.active {
  flex: 0 0 100%;
}
#heroTwinBanner .bannerWrapper:hover .bbanner.hidden {
  flex: 0 0;
}
#heroTwinBanner .bannerWrapper .bannerLeft img {
  object-position: 20% center;
}
#heroTwinBanner .bannerWrapper .bannerRight img {
  object-position: 80% center;
}
#heroTwinBanner .smallPop {
  position: absolute;
  background-color: rgba(28, 27, 24, 0.5);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  width: 430px;
  border-radius: 755px;
  color: #fff;
  padding: 15px 30px;
  left: 50%;
  top: 110px;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
  place-content: center;
  text-align: center;
  overflow: hidden;
  z-index: 9;
  opacity: 1;
  text-decoration: none;
  transition: 0.5s all ease;
}
#heroTwinBanner .smallPop.hiden {
  opacity: 0;
  visibility: hidden;
}
#heroTwinBanner .smallPop.left {
  left: 55%;
}
#heroTwinBanner .smallPop.right {
  left: 45%;
}
#heroTwinBanner .smallPop h4 {
  margin-bottom: 20px;
}
#heroTwinBanner .smallPop > i {
  position: absolute;
  font-size: 100px;
  opacity: 0.1;
  left: -10px;
  right: -10px;
}
#heroTwinBanner .smallPop .d-flex .w50 {
  width: 50%;
  flex: 0 0 50%;
}
#heroTwinBanner .smallPop .d-flex .w50 button, #heroTwinBanner .smallPop .d-flex .w50 a {
  text-decoration: none;
  color: #fff;
  background-color: rgba(45, 45, 45, 0.7);
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 1;
  transition: 0.5s all ease;
  text-align: center;
  border: none;
  width: 170px;
}
#heroTwinBanner .smallPop .d-flex .w50:first-child {
  text-align: right;
}
#heroTwinBanner .smallPop .d-flex .w50:first-child button, #heroTwinBanner .smallPop .d-flex .w50:first-child a {
  border-radius: 25px 0 0 25px;
  background-color: rgba(45, 45, 45, 0.5);
}
#heroTwinBanner .smallPop .d-flex .w50:last-child {
  text-align: left;
}
#heroTwinBanner .smallPop .d-flex .w50:last-child a {
  border-radius: 0 25px 25px 0;
}
#heroTwinBanner .smallPop .d-flex .w50 button:hover, #heroTwinBanner .smallPop .d-flex .w50 a:hover {
  background-color: #fff;
  color: #000;
}
#heroTwinBanner .smallPop.left .d-flex .w50:first-child button, #heroTwinBanner .smallPop.left .d-flex .w50:first-child a {
  background-color: #FAD32E;
  color: #000000;
}
#heroTwinBanner .smallPop.right .d-flex .w50:last-child button, #heroTwinBanner .smallPop.right .d-flex .w50:last-child a {
  background-color: #FAD32E;
  color: #000000;
}
#heroTwinBanner #openUpload, #heroTwinBanner #closeUpload {
  display: none;
}

#bannerButtonSide {
  padding: 5px 20px 30px;
  border-radius: 10px;
  background-color: #FAD32E;
  border: none;
  position: fixed;
  left: 100%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(270deg);
          transform: translate(-50%, -50%) rotate(270deg);
  white-space: nowrap;
  z-index: 1030;
  transition: 0.3s -webkit-transform cubic-bezier(0.27, 1.55, 0.58, 2.15);
  transition: 0.3s transform cubic-bezier(0.27, 1.55, 0.58, 2.15);
  transition: 0.3s transform cubic-bezier(0.27, 1.55, 0.58, 2.15), 0.3s -webkit-transform cubic-bezier(0.27, 1.55, 0.58, 2.15);
  -webkit-animation-name: startAnimation;
          animation-name: startAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
#bannerButtonSide:hover {
  -webkit-transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
          transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
}

@-webkit-keyframes startAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
            transform: translate(-50%, -50%) rotate(270deg);
  }
  70% {
    -webkit-transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
            transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
            transform: translate(-50%, -50%) rotate(270deg);
  }
}

@keyframes startAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
            transform: translate(-50%, -50%) rotate(270deg);
  }
  70% {
    -webkit-transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
            transform: translate(calc(-50% - 10px), -50%) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
            transform: translate(-50%, -50%) rotate(270deg);
  }
}
.bannerPopupBg {
  position: fixed;
  display: none;
  z-index: 1041;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.bannerPopupBg .bannerPopup {
  position: absolute;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 30px;
  top: 30px;
  border-radius: 50px;
  overflow: hidden;
}
.bannerPopupBg .closeBanner {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: #FFFFFF;
  border-radius: 25px;
  transition: 0.3s all ease;
}
.bannerPopupBg .closeBanner:hover {
  background-color: #f44336;
  color: #FFFFFF;
}

.box {
  position: absolute;
  bottom: 90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 9;
  display: block;
  transition: 0.5s left ease;
}
.box span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: -10px;
  -webkit-animation: animate 2s infinite;
          animation: animate 2s infinite;
  transition: 0.3s all ease;
}
.box span:nth-child(2) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.box span:nth-child(3) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.box:hover span {
  border-color: #FAD32E;
  border-bottom-width: 5px;
  border-right-width: 5px;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
            transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
            transform: rotate(45deg) translate(20px, 20px);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
            transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
            transform: rotate(45deg) translate(20px, 20px);
  }
}
.overflowHide {
  overflow: hidden;
}

#landingBanner {
  min-height: 100vh;
  background-color: #111111;
  color: #fff;
  display: grid;
  align-items: center;
  padding-top: 100px;
}
#landingBanner h1 {
  font-size: 70px;
}
#landingBanner h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
#landingBanner .zForm.white .inpGroup .react-tel-input input[type=tel] {
  border-radius: 0 10px 10px 0;
}
#landingBanner .zForm.white .inpGroup .react-tel-input .flag-dropdown {
  margin-right: 0;
  border-radius: 10px 0 0 10px;
  padding-right: 0;
}
#landingBanner .container > .row {
  align-items: center;
}

#whyClockhash {
  background-color: #111111;
  color: #fff;
  position: relative;
  z-index: 1;
}
#whyClockhash .whycCard {
  text-align: center;
  margin-bottom: 25px;
}
#whyClockhash .whycCard img {
  background-color: #FAD32E;
  border-radius: 50%;
  margin-bottom: 25px;
}
#whyClockhash img.questionDesign {
  position: absolute;
  top: 50%;
  right: 10%;
  height: 80%;
  width: auto;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

#notification {
  background-color: #111;
  color: #fff;
}
#notification #n-email {
  max-width: 588px;
}
#notification h2 {
  font-size: 90px;
}
#notification h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.whatsapp {
  position: fixed;
  right: 20px;
  bottom: 80px;
  z-index: 900;
}
.whatsapp a {
  display: block;
  color: #000;
  text-decoration: none;
  position: relative;
}
.whatsapp a i {
  background-color: #25D366;
  color: #fff;
  width: 70px;
  height: 70px;
  display: grid;
  place-content: center;
  border-radius: 35px;
  font-size: 36px;
}
.whatsapp a span {
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  font-weight: 600;
  top: 50%;
  right: 0;
  line-height: 1;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  padding: 5px 10px 5px 5px;
  border-radius: 10px 0 0 10px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}
.whatsapp a:hover {
  text-decoration: none;
}
.whatsapp a:hover span {
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
  right: calc(100% - 5px);
  opacity: 1;
  visibility: visible;
}

#qsummary {
  background: linear-gradient(0deg, #1C1B18, #000000);
  color: #FFFFFF;
}

.graText {
  color: #b2b2b2 !important;
}

#more-about {
  background-color: #1C1B18;
}

#eligibility .borderWrapper > * {
  padding-top: 12px;
  padding-bottom: 12px;
  border: solid #3f3f3f;
  border-width: 0 0 0 1px;
}
#eligibility .borderWrapper > *:nth-child(1), #eligibility .borderWrapper > *:nth-child(4) {
  border-left-width: 0px;
}
#eligibility .borderWrapper > *:nth-child(1), #eligibility .borderWrapper > *:nth-child(2), #eligibility .borderWrapper > *:nth-child(3) {
  border-bottom-width: 1px;
}

.bigText {
  font-size: 24px;
}

#evaluation-criteria {
  background-image: url(https://images.pexels.com/photos/3810753/pexels-photo-3810753.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
#evaluation-criteria strong {
  color: #fff;
}
#evaluation-criteria::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000 0%, rgba(14, 13, 12, 0) 51.56%, #1C1B18 100%);
  z-index: 2;
}
#evaluation-criteria::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
#evaluation-criteria .container {
  z-index: 3;
  position: relative;
}
#evaluation-criteria .topLine {
  margin-bottom: 40px;
}
#evaluation-criteria .topLine > * {
  position: relative;
  padding-top: 30px;
  margin-top: 20px;
}
#evaluation-criteria .topLine > * h4 {
  color: #FFFFFF;
}
#evaluation-criteria .topLine > *::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 5px;
}
#evaluation-criteria .topLine > *::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #FFFFFF;
  top: -5px;
}
#evaluation-criteria .topLine > *:first-child::after {
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  left: 50%;
}
#evaluation-criteria .topLine > *:last-child::after {
  width: 50%;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

#ideaForm h4 {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
#ideaForm h5 {
  color: #FAD32E;
  font-weight: 70;
  text-transform: uppercase;
}
#ideaForm a {
  text-decoration: none;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
}
#ideaForm a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  transition: 0.3s width ease;
}
#ideaForm a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
#ideaForm a:hover::before {
  width: 0%;
}
#ideaForm .iconDiv {
  margin-bottom: 10px;
  display: flex;
}
#ideaForm .iconDiv .icon {
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  display: grid;
  place-content: center;
  color: transparent;
  -webkit-text-stroke: 1px #000000;
  margin-right: 8px;
  background-color: #FAD32E;
  border-radius: 13px;
}
#ideaForm .iconDiv .textSection {
  flex: 0 1 auto;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.landingBanner {
  display: grid;
}
.landingBanner .bannerText {
  background-color: rgba(0, 0, 0, 0.5);
}

#landingAbout.sectionPad {
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
}
#landingAbout.sectionPad .picside img {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1626px) {
  header.clkHead {
    font-size: 14px;
  }
}
@media (max-width: 1525px) {
  header.clkHead {
    height: auto;
  }
  header.clkHead .clkNav {
    height: auto;
  }
  header.clkHead .clkNav .navComp {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  header.clkHead .clkNav .navComp > ul {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
  header.clkHead .clkNav .navComp > ul.rightlist {
    padding-left: 0;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li:first-child {
    margin-left: 0;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li {
    margin-left: 10px;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li button.altBtn,
  header.clkHead .clkNav .navComp > ul.rightlist li a.mainBtn,
  header.clkHead .clkNav .navComp > ul.rightlist li a.altBtn {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li .langSelector {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  header.clkHead .clkNav .navLogo img {
    height: 50px;
    max-height: 50px;
  }
  #heroTwinBanner .smallPop {
    top: 70px;
  }
}
@media (max-width: 1199px) {
  header.clkHead {
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
  header.clkHead .clkNav .navLogo img {
    height: 75px;
    max-height: 75px;
  }
  header.clkHead .clkNav .navToggler {
    display: block;
    margin-left: auto;
    margin-right: 20px;
    border: none;
    background-color: transparent;
  }
  header.clkHead .clkNav .navToggler span {
    display: block;
    height: 5px;
    width: 30px;
    margin: 5px;
    background-color: #fff;
    border-radius: 3px;
    transition: 0.3s margin ease;
  }
  header.clkHead .clkNav .navToggler span:first-child {
    width: 15px;
    margin-left: 5px;
  }
  header.clkHead .clkNav .navToggler span:last-child {
    width: 15px;
    margin-left: 20px;
  }
  header.clkHead .clkNav .navToggler:hover span:first-child {
    margin-left: 20px;
  }
  header.clkHead .clkNav .navToggler:hover span:last-child {
    margin-left: 5px;
  }
  header.clkHead .clkNav .navComp {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    top: 100%;
    padding-bottom: 10px;
  }
  header.clkHead .clkNav .navComp::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: calc(100% - 50px);
    top: 0;
    left: 25px;
    position: absolute;
  }
  header.clkHead .clkNav .navComp > ul.navList {
    display: block;
    font-size: 16px;
    padding-top: 20px;
  }
  header.clkHead .clkNav .navComp > ul.rightlist {
    display: block;
    font-size: 16px;
    padding-left: 20px;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li:not(:first-child) {
    margin-bottom: 10px;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li {
    margin-left: 0;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li a {
    display: block;
    width: 100%;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li a.mainBtn, header.clkHead .clkNav .navComp > ul.rightlist li a.altBtn {
    padding: 10px 20px !important;
    text-align: center;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li a.altBtn {
    text-align: center;
    margin-bottom: 10px;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li .singleSpace {
    display: none;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li button.altBtn.logOutBtn {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    padding: 10px 20px !important;
  }
  header.clkHead .clkNav .navComp > ul.rightlist li .langSelector .langSelected {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  header.clkHead .clkNav .navComp {
    background-color: #1C1B18;
  }
  #augmentation .paw50 .developerCard {
    -webkit-transform: none;
            transform: none;
  }
  #heroTwinBanner .smallPop {
    top: 90px;
  }
  #landingBanner h1 {
    font-size: 56px;
  }
  #landingBanner h3 {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  #second #wayWorking .col-lg-6:first-child {
    margin-top: 0;
  }
  #second #wayWorking .col-lg-6:first-child .glassCard {
    flex-direction: row;
  }
  #second #wayWorking .col-lg-6:first-child .glassCard .textSection {
    text-align: left;
    padding-left: 0;
    padding-right: 55px;
  }
  #augmentation .paw50 .splide__slide:nth-child(even),
  #augmentation .paw50 .owl-item:nth-child(even) {
    margin-top: 0;
  }
  #howToHire::before {
    width: 250px;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: linear-gradient(to top, transparent 1%, #82712e);
  }
  #howToHire .col-lg-4 {
    padding-bottom: 24px;
  }
  #howToHire .col-lg-4::before {
    top: calc(50% - 5px);
    left: 12px;
    -webkit-transform: none;
            transform: none;
  }
  #howToHire .col-lg-4 .glassCard {
    margin-top: 0;
    margin-left: 35px;
  }
  #howToHire .col-lg-4:first-child::after {
    width: 1px;
    height: 50%;
    top: 50%;
    left: 17px;
  }
  #howToHire .col-lg-4::after {
    width: 1px;
    height: 100%;
    top: 0;
    left: 17px;
  }
  #howToHire .col-lg-4:last-child::after {
    width: 1px;
    height: 50%;
    top: 0;
    left: 17px;
  }
  #howToHire.guideline .col-lg-4 .glassCard {
    margin-left: 0;
    margin-bottom: 0;
  }
  #screening #top3percent .row:not(:last-child) {
    position: relative;
  }
  #screening #top3percent .row:not(:last-child) .col-lg-2.align-items-center {
    position: unset;
  }
  #screening #top3percent .row:not(:last-child) .col-lg-2.align-items-center::before {
    left: 74.5px;
    top: 62.5px;
  }
  #augmentation .paw50 {
    position: unset;
    width: 100%;
    -webkit-transform: none;
            transform: none;
  }
  footer .topFooter .col-lg-6 ul.conDetails li .addressFooter {
    display: flex;
    flex-wrap: wrap;
  }
  footer .topFooter .col-lg-6 ul.conDetails li .addressFooter p {
    width: 100%;
    flex: 0 0 100%;
  }
  footer .topFooter .col-lg-6 ul.conDetails li .addressFooter .line {
    width: 100%;
    flex: 0 0 100%;
    height: 30px;
  }
  footer .topFooter .col-lg-6 ul.conDetails li .addressFooter .line::before {
    content: "";
    width: 100%;
    height: 1px;
    margin-top: 14px;
  }
  #landingBanner h1 {
    font-size: 48px;
  }
  #notification h2 {
    font-size: 56px;
  }
  #evaluation-criteria .topLine > * {
    margin-top: 0;
    padding-bottom: 20px;
  }
  #evaluation-criteria .topLine > *::before {
    left: -12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 11px;
    height: 11px;
  }
  #evaluation-criteria .topLine > *::after {
    width: 1px;
    height: 100%;
    left: -7px;
    top: 0;
  }
  #evaluation-criteria .topLine > *:first-child:after {
    width: 1px;
    height: 50%;
    top: 50%;
    left: -7px;
  }
  #evaluation-criteria .topLine > *:last-child:after {
    width: 1px;
    height: 50%;
    top: 0;
    left: -7px;
  }
  #landingAbout.sectionPad {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  #landingAbout.sectionPad .picside {
    padding-top: 50%;
    position: relative;
    margin-bottom: 20px;
  }
  #landingAbout.sectionPad .picside img {
    width: 100%;
    border-radius: 30px;
  }
}
@media (max-width: 768px) {
  #augmentation .col-lg-6 .col-md-6:last-child {
    margin-top: 0;
  }
  footer .topFooter .col-lg-6 .listLinks .footerList {
    margin-top: 40px;
  }
  #screening #top3percent .row .col-lg-2.align-items-center {
    margin-bottom: 10px;
    justify-content: center;
  }
  #screening #top3percent .row:not(:last-child) .col-lg-2.align-items-center::before {
    left: calc(50% - 1px);
  }
  #landingBanner .container > .row {
    flex-direction: column-reverse;
  }
  #landingBanner .container > .row img.img-fluid {
    max-width: 50%;
    height: auto;
    display: block;
    margin: auto;
    margin-bottom: 15px;
  }
  #whyClockhash img.questionDesign {
    max-width: 80%;
  }
  #notification img.img-fluid {
    max-width: 50%;
    height: auto;
    display: block;
    margin: auto;
    margin-bottom: 15px;
  }
  .whatsapp a {
    left: 3%;
    bottom: 3%;
    text-align: left;
  }
  .whatsapp a i {
    left: 3%;
    bottom: 3%;
    text-align: center;
  }
  #eligibility .borderWrapper > *:nth-child(3) {
    border-width: 0 0 1px 0;
  }
  #eligibility .borderWrapper > *:nth-child(4) {
    border-width: 0 0 1px 1px;
  }
  #eligibility .borderWrapper > *:nth-child(5) {
    border-width: 0 0 0px 0px;
  }
}
@media (max-width: 576px) {
  #heroSection .socialList {
    left: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  #second #wayWorking .col-lg-6 .glassCard {
    display: grid;
    text-align: center;
  }
  #second #wayWorking .col-lg-6 .glassCard img {
    width: 75px;
    height: 75px;
  }
  #second #wayWorking .col-lg-6 .glassCard .textSection {
    text-align: center !important;
    padding-right: 0 !important;
  }
  #second #engagementModel .glassCard img,
  #second #topTalents .glassCard img {
    width: 75px;
    height: 75px;
  }
  footer .topFooter .col-lg-6 .listLinks {
    display: block;
  }
  footer .topFooter .col-lg-6 .listLinks .vLine {
    width: 100%;
    height: 1px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px;
  }
  #joinUs .logo-bg {
    margin-top: 20px;
  }
  #joinUs::after {
    width: 55px;
    height: 55px;
  }
  #second #wayWorking .img-section img {
    width: 75px;
    height: 75px;
  }
  #augmentation .col-lg-6 .col-md-6 .glassCard .icon {
    font-size: 50px;
    width: 80px;
    height: 80px;
  }
  #augmentation .col-lg-6 .col-md-6 .glassCard .icon i {
    -webkit-text-stroke: 1px #FFFFFF;
  }
  header.clkHead.scrolled .clkNav,
  header.clkHead .clkNav {
    height: auto;
  }
  header.clkHead.scrolled .clkNav .navLogo img,
  header.clkHead .clkNav .navLogo img {
    width: 60vw;
    max-width: 307px;
    height: auto;
  }
  header.clkHead.scrolled .clkNav .navComp,
  header.clkHead .clkNav .navComp {
    height: calc(100vh - 68px);
    padding-top: 20vh;
  }
  header.clkHead.scrolled .clkNav .navComp > ul li,
  header.clkHead .clkNav .navComp > ul li {
    text-align: center;
  }
  #offShore .img-fill {
    border-radius: 40px;
  }
  #calendar-widget-popup-button-container #calendar-widget-popup-button {
    font-size: 0 !important;
    padding: 10px !important;
    width: 60px;
    height: 60px;
    border-radius: 30px !important;
  }
  #calendar-widget-popup-button-container #calendar-widget-popup-button::before {
    content: "\F218";
    display: inline-block;
    font-size: 24px;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent h3 {
    margin-top: 100px;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent h3::after {
    content: "\F105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    display: grid;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: auto;
    color: #000000;
    place-content: center;
    border-radius: 25px;
    font-size: 14px;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent .viewBtn {
    position: absolute;
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  #heroTwinBanner .bannerWrapper .bbanner, #heroTwinBanner .bannerWrapper:hover .bbanner, #heroTwinBanner .bannerWrapper:hover .bbanner:hover {
    flex: 0 0 100%;
    height: 50vh;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText, #heroTwinBanner .bannerWrapper:hover .bbanner .bannerText, #heroTwinBanner .bannerWrapper:hover .bbanner:hover .bannerText {
    padding: 20px;
    height: 100%;
    display: grid;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
  }
  #heroTwinBanner .bannerWrapper .bbanner.active, #heroTwinBanner .bannerWrapper:hover .bbanner.active, #heroTwinBanner .bannerWrapper:hover .bbanner:hover.active {
    height: 100vh;
  }
  #heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent, #heroTwinBanner .bannerWrapper:hover .bbanner.active .bannerText .bannerContent, #heroTwinBanner .bannerWrapper:hover .bbanner:hover.active .bannerText .bannerContent {
    max-width: 100%;
  }
  #heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent h3::after, #heroTwinBanner .bannerWrapper:hover .bbanner.active .bannerText .bannerContent h3::after, #heroTwinBanner .bannerWrapper:hover .bbanner:hover.active .bannerText .bannerContent h3::after {
    display: none;
  }
  #heroTwinBanner .bannerWrapper .bbanner.hidden, #heroTwinBanner .bannerWrapper:hover .bbanner.hidden, #heroTwinBanner .bannerWrapper:hover .bbanner:hover.hidden {
    flex: 0 0 100%;
    height: 0;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent button.back, #heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent button.back {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 10px !important;
    top: 20px;
    left: 20px;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent button.back i, #heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent button.back i {
    display: block;
  }
  #heroTwinBanner .bannerWrapper .bbanner .bannerText .bannerContent button.back span, #heroTwinBanner .bannerWrapper .bbanner.active .bannerText .bannerContent button.back span {
    display: none;
  }
  #heroTwinBanner .smallPop {
    width: 100%;
    top: auto;
    bottom: 0;
    background-color: #1C1B18;
    left: 50% !important;
    border-radius: 20px 20px 0 0;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    padding: 20px;
    position: fixed;
    z-index: 1041;
    overflow: inherit;
    transition-delay: 0.5s;
  }
  #heroTwinBanner .smallPop::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #1C1B18;
    opacity: 0;
    visibility: hidden;
    left: 0;
    bottom: 0;
    z-index: -3;
    transition: 0.5s opacity ease;
    transition-delay: 0s;
  }
  #heroTwinBanner .smallPop.active {
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    transition-delay: 0s;
  }
  #heroTwinBanner .smallPop.active::before {
    opacity: 0.7;
    visibility: visible;
    transition-delay: 0.5s;
  }
  #heroTwinBanner .smallPop.hiden {
    opacity: 1;
    visibility: visible;
  }
  #heroTwinBanner .smallPop h4 {
    margin-bottom: 20px;
  }
  #heroTwinBanner .smallPop .d-flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  #heroTwinBanner .smallPop .d-flex .w50 {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
  #heroTwinBanner .smallPop .d-flex .w50 button, #heroTwinBanner .smallPop .d-flex .w50 a {
    display: block;
    width: 100% !important;
    padding: 15px;
    margin: 5px auto 0;
    text-align: center;
    border-radius: 10px !important;
  }
  #heroTwinBanner #openUpload {
    display: block;
    position: absolute;
    top: 50vh;
    z-index: 1030;
    left: 5%;
    -webkit-transform: translateY(-50%) rotateX(0deg);
            transform: translateY(-50%) rotateX(0deg);
    border-radius: 30px;
    padding: 15px 25px;
    border: none;
    color: #000;
    background-color: rgba(250, 211, 46, 0.5);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    width: 90%;
    transition: 0.5s all ease;
    transition-delay: 0.3s;
  }
  #heroTwinBanner #openUpload.hide {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
    -webkit-transform: translateY(-50%) rotateX(90deg);
            transform: translateY(-50%) rotateX(90deg);
  }
  #heroTwinBanner #closeUpload {
    display: grid;
    width: 50px;
    height: 50px;
    place-content: center;
    margin-left: auto;
    border-radius: 25px;
    border: none;
    background-color: #402929;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
  }
  header.clkHead .clkNav .navComp > ul li .langSelector ul.langList,
  #second #topTalents .glassCard,
  #second #engagementModel .glassCard,
  #second #wayWorking .col-lg-6 .glassCard,
  #augmentation .col-lg-6 .col-md-6 .glassCard,
  #howToHire .col-lg-4 .glassCard,
  #testimonial .glassCard,
  .blogCard,
  .bgOverlay,
  .glassSection,
  #topInnerPage.whyUs .glassCard,
  #screening #top3percent .icon,
  #screening #top3percent .glassCard,
  #aboutUs #topInnerPage .glassCard,
  #aboutUs #ourTeam .developerCard,
  #topInnerPage.career .glassCard,
  #topInnerPage.career .jobCard,
  #topInnerPage.error404 .glassCard,
  #topInnerPage.hireFromUs .glassCard,
  #developers .glassCard,
  .CookieConsent,
  #topInnerPage.login .glassCard,
  #topInnerPage.myAccountCandidate .glassCard,
  #topInnerPage.editProfile .glassCard,
  header.clkHead,
  header.clkHead .clkNav .navComp {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .bgOverlay .popupCard {
    padding: 60px 20px 20px;
    border-radius: 30px;
  }
  .bgOverlay .popupCard .closeBtn {
    right: 10px;
    top: 10px;
  }
  .bannerPopupBg .bannerPopup {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
  }
  #landingBanner h1,
  #notification h2 {
    font-size: calc(2rem + 0.9vw);
  }
  #landingBanner h3 {
    font-size: 16px;
  }
}/*# sourceMappingURL=main.css.map */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

